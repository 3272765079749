$gold-star: #ed4d1c;

// kiritan base color
$kiri-base-color: rgb(30, 15, 20);
$kiri-base-lighter-color: #e4d0da;

// Variables for defaults in UI
$base-shadow-color: $kiri-base-color;
$base-overlay-background: rgba($base-shadow-color, 0.2);
$base-border-color: $kiri-base-lighter-color;

// Tell UI to use selected colors
$ui-highlight-color: #2b90d9;
$ui-base-color: rgba($kiri-base-color, 0.8);
$ui-base-lighter-color: lighten(desaturate($ui-base-color, 20%), 45%);
$ui-primary-color: $kiri-base-lighter-color;
$ui-secondary-color: lighten($kiri-base-lighter-color, 50%);

$ui-button-background-color: darken($ui-highlight-color, 5%);
$ui-button-focus-background-color: darken($ui-highlight-color, 25%);

// Variables for texts
$primary-text-color: $base-border-color;
$darker-text-color: darken($kiri-base-lighter-color, 20%);
